export const sea_env = {
    REACT_APP_API_URL: "https://sea-dev.urbanform.us/api",
    REACT_APP_MAPBOX_ACCESS_TOKEN:
      "pk.eyJ1IjoicXZ0MjgyIiwiYSI6ImNrZmtxOWdveDB2ZXkyeG56NzR0aW9jbXUifQ.Plxub9XCzhhAbZ3MJbAXMw",
    REACT_APP_MAPBOX_STYLE_URL_BASIC:
      "mapbox://styles/qvt282/clq3f0abc00bo01plh3pj9r3m",//multi-region-style
    REACT_APP_MAPBOX_STYLE_URL_SATELLITE:
      "mapbox://styles/qvt282/cls8mnti901f001r43rix1v44",
    REACT_APP_MAPBOX_STYLE_URL_MBENVELOPE:
      "mapbox://styles/qvt282/clmqcraac023301nza13c8eyl",
    REACT_APP_MAPBOX_LAYER_NAME: "sea_blv_high_zoom",
    REACT_APP_STRIPE_KEY:
      "pk_live_51J3keRKwuRPRmetLx954L0Hp0oEMK3O1xaVJ02YH9ojGDTzaHaQCQXRw5Pox6VUfCQl1ZS4PfU63M4ys66E4F61g00CDRf3ISt",
    REACT_APP_REGION: "seattle",
    REACT_APP_BBOX: "-122.450180,47.513954,-122.086258,47.744403",
    REACT_APP_MAP_CENTER: "-122.259636,47.608478",
    REACT_APP_SUBREGION_LAYER:"blv_region_9-16",
    REACT_APP_SUB_REGION: "bellevue",
    REACT_APP_SUB_CITY_NAME: "Bellevue, CA",  
    REACT_APP_CITY_NAME: "Seattle, Washington",
    REACT_APP_CITIES_LIST:
      '{ "portland": [], "seattle": [], "san_diego": [], "austin": [] }',
    REACT_APP_PROXIMITY: "-122.169857,47.608825",
    REACT_APP_MB_TILES_LIST:"taxlots-fill",
    REACT_APP_ZOOM_LEVEL:10.9,
  };
