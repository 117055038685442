import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as turf from "@turf/turf";
import mapboxgl from "mapbox-gl";
import "mapbox-gl-style-switcher/styles.css";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { connect, useSelector } from "react-redux";
import Switch from "react-switch";
import { Button, Image } from "semantic-ui-react";
import selectedEnv from "../Universal_CONFIG";
import { setDataWindow, setMapSearch } from "../actions/actions_datawindow";
import {
  getAddressByLngLat,
  getSelectInfo,
  infoSearchMapAddress,
  setInfoCurrentValue,
  setInfoUpdated,
} from "../actions/actions_info";
import { setGeojson } from "../actions/actions_taxlot";
import { setAlertMessage, setLoader } from "../actions/actions_user";
import { BBOX, GEOCODER_CITY, INFO_CONSTANT, MAP_CENTER } from "../constants";
import "../css/Map.scss";
import fly from "../images/map-email.svg";
import { CITY_BOUNDARY } from "../utils/PortlandBoundary";
import { Capitalize } from "../utils/Utils";

const Map = ({
  sidebarVisible,
  zoneFilterSelectedOptions,
  zoneCatFilterSelectedOptions,
  lotTypeFilterSelectedOptions,
  htlimLotDefaultOptions,
  htlimLotSelectedOptions,
  htlimLotBonusDefaultOptions,
  htlimLotBonusSelectedOptions,
  unitLotMinDefaultOptions,
  unitLotMinSelectedOptions,
  unitLotMaxDefaultOptions,
  unitLotMaxSelectedOptions,
  envpGrndLotAreaDefaultOptions,
  envpGrndLotAreaSelectedOptions,
  envpGrndFtptMaxDefaultOptions,
  envpGrndFtptMaxSelectedOptions,
  envpBldgMaxAreaDefaultOptions,
  envpBldgMaxAreaSelectedOptions,
  envpBldgMaxResDefaultOptions,
  envpBldgMaxResSelectedOptions,
  //   envpBldgDeltAreaDefaultOptions,
  //   envpBldgDeltAreaSelectedOptions,
  farLotDefaultOptions,
  farLotSelectedOptions,
  farLotBonusDefaultOptions,
  farLotBonusSelectedOptions,
  dispatch,
  taxlotData,
  setCurrentContent,
}) => {
  const [map, setMap] = useState(null);
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [geoCoder, setGeoCoder] = useState(null);
  const { infoValuesUpdated, infoDefaultValues } = useSelector(
    (state) => state.info
  );
  const { showMap } = useSelector((state) => state.dataWindow.show);
  const { selectedProperty } = useSelector((state) => state.filters);
  const { place_name } = useSelector(
    (state) => state.info.infoSearchMapAddress
  );
  const dataWindow = useSelector((state) => state.dataWindow.dataWindow);
  const primeAccNums = useSelector((state) => state.search.primaccnum);
  const totalResults = useSelector(
    (state) => state.filters.filterResult.totalCount
  );
  const [showingResults, setShowingResults] = useState(null);
  const [isFit, setIsSetFit] = useState(false);
  const [initialStyle, setInitialStyle] = useState("Basic-prod");
  const [isBuildingFootprintsActive, setIsBuildingFootprintsActive] =
    useState(true);
  const [isSatelliteView, setIsSatelliteView] = useState(false);
  const [envelope3dStyle, setEnvelope3dStyle] = useState(false);
  const [mapStyleGlobally, setMapStyleGlobally] = useState(null);
  const { user } = useSelector((state) => state.user);
  const { dynoButtonsResponse } = useSelector((state) => state.DynoInfo);

  const handleInfoDetails = () => {
    dispatch(setInfoCurrentValue(INFO_CONSTANT));
  };
  const webType = window.location.href;
  // const cls = webType === "https://pdx.urbanform.us/" ? "controls-box " : "controls-box2 ";
  const cls = "controls-box2 ";

  // filter geocoder list item
  // const filter = (item) => {
  //   // returns true if item is in specified city/place
  //   return item.context.some((i) => {
  //     const geoCoderValue = GEOCODER_CITY[0].toUpperCase()+GEOCODER_CITY.slice(1)
  //     // console.log("fari2",selectedEnv.REACT_APP_SUB_REGION?.length&&selectedEnv?.REACT_APP_SUB_REGION[0].toUpperCase()+selectedEnv?.REACT_APP_SUB_REGION.slice(1));
  //     // const selectGeoCodedJuris = localStorage.getItem('sub-city')
  //     // console.log("fari",selectGeoCodedJuris,i,i.id.split(".").shift() === "place"
  //     // && (i.text === selectedEnv.REACT_APP_REGION.toUpperCase()||i.text === selectedEnv?.REACT_APP_SUB_REGION?.toUpperCase()))
  //     if(selectedEnv.REACT_APP_SUB_REGION)
  //     return i.id.split(".").shift() === "place"
  //     && (i.text === geoCoderValue||i.text === selectedEnv?.REACT_APP_SUB_REGION[0].toUpperCase()+selectedEnv?.REACT_APP_SUB_REGION.slice(1));
  //     else return i.id.split(".").shift() === "place"
  //     && (i.text === geoCoderValue);

  //   });
  // };
  const filter = (item) => {
    // returns true if item is in specified city/place

    // return item.context.some((i) => {
    //   return i.id.split(".").shift() === "place" && i.text === "Seattle";
    // });
    const result = item.context.some((i) => {
      const geoCoderValue =
        GEOCODER_CITY[0].toUpperCase() + GEOCODER_CITY.slice(1);

      if (selectedEnv.REACT_APP_SUB_REGION) {
        return (
          (i.id.split(".").shift() === "place" && i.text === geoCoderValue) ||
          (i.id.split(".").shift() === "place" &&
            i.text ===
              selectedEnv?.REACT_APP_SUB_REGION[0].toUpperCase() +
                selectedEnv?.REACT_APP_SUB_REGION.slice(1))
        );
      } else if (!selectedEnv.REACT_APP_SUB_REGION) {
        return i.id.split(".").shift() === "place" && i.text === geoCoderValue;
      }
    });
    return result;
  };
  //render geocoder list items according to requirements
  const renderItem = (item) => {
    var place_name = item.place_name.replace(", United States", "");
    // extract the item's maki icon or use a default
    return `<div class='geocoder-dropdown-item'>
      <span class='geocoder-dropdown-text'>
      ${place_name}
      </span>
    </div>`;
  };

  const fetchPropertyData = (primaccnum, lngLat) => {
    let body = {
      primaccnum: primaccnum,
    };

    handleInfoDetails();
    dispatch(getSelectInfo(body, INFO_CONSTANT, lngLat, Math.random(), user));
  };
  const setGeoJson = (feature) => {
    dispatch(
      setGeojson({
        type: "FeatureCollection",
        features: [
          {
            properties: {
              "fill-opacity": 0,
              "stroke-width": 4,
              "stroke-opacity": 1,
              stroke: "%2300adef",
            },
            type: "Feature",
            geometry: { ...feature.geometry },
          },
        ],
      })
    );
    // dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, choosePlan: {} } })); // hide the second window when click the property
  };
  // Initialize mapconstant
  console.log("jijis", dynoButtonsResponse);
  useEffect(() => {
    mapboxgl.accessToken = selectedEnv.REACT_APP_MAPBOX_ACCESS_TOKEN;
    // console.log("map",selectedEnv.REACT_APP_API_URL)
    const map = new mapboxgl.Map({
      container: "mapbox-map",
      style: selectedEnv.REACT_APP_MAPBOX_STYLE_URL_BASIC, // stylesheet location
      center: selectedEnv.REACT_APP_MAP_CENTER.split(","), // starting position [lng, lat]
      zoom: selectedEnv.REACT_APP_ZOOM_LEVEL, // starting zoom
    });
    map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
    map.addControl(
      new mapboxgl.ScaleControl({
        maxWidth: 80,
        unit: "metric",
      }),
      "bottom-right"
    );
    map.addControl(
      new mapboxgl.NavigationControl({
        showZoom: false,
      }),
      "bottom-right"
    );

    // map.addControl(new MapboxStyleSwitcherControl(
    //   [{ title: "Satellite", uri: "mapbox://styles/qvt282/cksecovrm1uck17mzvfatf489" },
    //   { title: "Streets", uri: "mapbox://styles/qvt282/cks7jqq2k7nji17q6lzeot944" }]
    // ), 'top-left');

    map.on("styledata", (e) => {
      const { name } = e.style.stylesheet;
      if (name === initialStyle) return;
      setInitialStyle(name);
    });
    const searchBarGeocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: "Search for an address here...",
      marker: false,
      countries: "us",
      bbox: BBOX,
      zoom: 18,
      types: "address",
      filter: (item) => filter(item),
      render: (item) => renderItem(item),
    }).on("result", (e) => {
      dispatch(setLoader(true));
      map.once("idle", () => {
        let thisTaxLot = map.queryRenderedFeatures(
          map.project(e.result.center),
          {
            layers: selectedEnv.REACT_APP_MB_TILES_LIST.includes(",")
              ? selectedEnv.REACT_APP_MB_TILES_LIST.split(",")
              : selectedEnv.REACT_APP_MB_TILES_LIST.split(","),
          }
        );
        if (thisTaxLot && thisTaxLot.length > 0) {
          map.fire("click", {
            lngLat: e.result.center,
            point: map.project(e.result.center),
            originalEvent: { type: "search" },
          });
        } else {
          dispatch(setInfoUpdated(true));
          dispatch(setAlertMessage("No data found!"));
          dispatch(
            setDataWindow({
              dataWindow: false,
              content: {
                isContact: null,
                contactInfo: {},
                choosePlan: {},
                isPayment: false,
                isInfo: true,
              },
            })
          );
          dispatch(setLoader(false));
        }
      });
    });

    const homeGeocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: "Search For an address...",
      marker: false,
      countries: "us",
      bbox: BBOX,
      zoom: 18,
      types: "address",
      filter: (item) => filter(item),
      render: (item) => filter(item),
    }).on("result", (e) => {
      dispatch(setLoader(true));
      map.once("idle", () => {
        let thisTaxLot = map.queryRenderedFeatures(
          map.project(e.result.center),
          {
            layers: selectedEnv.REACT_APP_MB_TILES_LIST.includes(",")
              ? selectedEnv.REACT_APP_MB_TILES_LIST.split(",")
              : selectedEnv.REACT_APP_MB_TILES_LIST.split(","),
          }
        );
        if (thisTaxLot && thisTaxLot.length > 0) {
          map.fire("click", {
            lngLat: e.result.center,
            point: map.project(e.result.center),
            originalEvent: { type: "search" },
          });
        } else {
          dispatch(setInfoUpdated(true));
          dispatch(setAlertMessage("No data found!"));
          dispatch(
            setDataWindow({
              dataWindow: false,
              content: {
                isContact: null,
                contactInfo: {},
                choosePlan: {},
                isPayment: false,
                isInfo: true,
              },
            })
          );
          dispatch(setLoader(false));
        }
      });
    });

    const infoGeocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: "Search for an address here...",
      marker: false,
      countries: "us",
      bbox: BBOX,
      proximity: selectedEnv.REACT_APP_PROXIMITY,
      zoom: 18,
      types: "address",
      filter: (item) => filter(item),
      render: (item) => renderItem(item),
    }).on("result", (e) => {
      dispatch(setLoader(true));
      map.once("idle", () => {
        let thisTaxLot = map.queryRenderedFeatures(
          map.project(e.result.center),
          {
            layers: selectedEnv.REACT_APP_MB_TILES_LIST.includes(",")
              ? selectedEnv.REACT_APP_MB_TILES_LIST.split(",")
              : selectedEnv.REACT_APP_MB_TILES_LIST.split(","),
          }
        );
        if (thisTaxLot && thisTaxLot.length > 0) {
          map.fire("click", {
            lngLat: e.result.center,
            point: map.project(e.result.center),
            originalEvent: { type: "search" },
          });
        } else {
          dispatch(setInfoUpdated(true));
          dispatch(setAlertMessage("No data found!"));
          dispatch(
            setDataWindow({
              dataWindow: false,
              content: {
                isContact: null,
                contactInfo: {},
                choosePlan: {},
                isPayment: false,
                isInfo: true,
              },
            })
          );
          dispatch(setLoader(false));
        }
      });
    });
    setGeoCoder(infoGeocoder);
    if ("geolocation" in navigator && isMobile === true) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLng(position.coords.longitude);
        setLat(position.coords.latitude);
      });
    }
    document
      .getElementById("mapbox-geocoder-home")
      .appendChild(homeGeocoder.onAdd(map));
    document
      .getElementById("mapbox-geocoder-info")
      .appendChild(infoGeocoder.onAdd(map));
    document
      .getElementById("mapbox-geocoder-search-bar")
      .appendChild(searchBarGeocoder.onAdd(map));
    // add event listener for geocoder's input element to trigger the geocoded address list
    infoGeocoder._inputEl.addEventListener("focus", () => {
      if (infoGeocoder._typeahead.data.length) {
        // if there was a list before, just display it, so the user can quickly choose a different result
        infoGeocoder._typeahead.data !== "" &&
          infoGeocoder._typeahead.update(infoGeocoder._typeahead.data);
      } else {
        // otherwise, get and show results
        infoGeocoder._inputEl.value !== "" &&
          infoGeocoder._geocode(infoGeocoder._inputEl.value);
      }
    });
    // add event listener for geocoder's input element to trigger the geocoded address list
    homeGeocoder._inputEl.addEventListener("focus", () => {
      if (homeGeocoder._typeahead.data.length) {
        // if there was a list before, just display it, so the user can quickly choose a different result
        homeGeocoder._typeahead.data !== "" &&
          homeGeocoder._typeahead.update(homeGeocoder._typeahead.data);
      } else {
        // otherwise, get and show results
        homeGeocoder._inputEl.value !== "" &&
          homeGeocoder._geocode(homeGeocoder._inputEl.value);
      }
    });

    map.once("load", () => {
      map.once("idle", () => {
        map.setLayoutProperty(
          "bldg-fill",
          "visibility",
          isBuildingFootprintsActive ? "visible" : "none"
        );
        // map.setLayoutProperty('building-footprints', 'visibility', 'none');
      });
    });
    setMap(map);
    map &&
      map.on("idle", (e) => {
        let temp = map.querySourceFeatures("composite", {
          sourceLayer:
            map.getZoom() > 20
              ? selectedEnv.REACT_APP_MAPBOX_LAYER_NAME
              : selectedEnv.REACT_APP_MAPBOX_LAYER_NAME,
          filter: ["in", "PRIMACCNUM", ...primeAccNums],
        });
        let temp2 = selectedEnv.REACT_APP_SUBREGION_LAYER
          ? map.querySourceFeatures("composite", {
              sourceLayer:
                map.getZoom() > 20
                  ? selectedEnv.REACT_APP_SUBREGION_LAYER
                  : selectedEnv.REACT_APP_SUBREGION_LAYER,
              filter: ["in", "PRIMACCNUM", ...primeAccNums],
            })
          : null;
        if (!map.getSource("filtered-lot")) {
          map.addSource("filtered-lot", {
            type: "geojson",
            data: { type: "FeatureCollection", features: temp },
          });
          map.addLayer({
            id: "filtered-lot",
            source: "filtered-lot",
            type: "line",
            paint: {
              "line-color": "#ff00ff",
              "line-width": 1.62,
            },
          });

          if (temp2) {
            map.addSource("filtered-lot2", {
              type: "geojson",
              data: { type: "FeatureCollection", features: temp2 },
            });
            map.addLayer({
              id: "filtered-lot2",
              source: "filtered-lot2",
              type: "line",
              paint: {
                "line-color": "#ff00ff",
                "line-width": 1.62,
              },
            });
          }
        } else {
          map
            .getSource("filtered-lot")
            .setData({ type: "FeatureCollection", features: temp });
          if (temp2)
            map
              .getSource("filtered-lot2")
              .setData({ type: "FeatureCollection", features: temp2 });
        }
      });
    map.on("load", () => {
      map.on(
        "click",
        selectedEnv.REACT_APP_MB_TILES_LIST.includes(",")
          ? selectedEnv.REACT_APP_MB_TILES_LIST.split(",")
          : selectedEnv.REACT_APP_MB_TILES_LIST.split(","),
        (e) => {
          if (e.features[0]?.properties.JURIS) {
            const x = e.features[0]?.properties.JURIS;
            const y = x.split("City of ");
            localStorage.setItem(
              "sub-city",
              y[1]?.toLowerCase().replace(/ +/g, "_")
            );
          }
          dispatch(
            infoSearchMapAddress({
              place_name: e.features[0].properties.ADDR,
            })
          );
          if (map.getZoom() < 14) return;
          var thisTaxLot = map.queryRenderedFeatures(e.point, {
            layers: selectedEnv.REACT_APP_MB_TILES_LIST.split(),
          });
          if (thisTaxLot) {
            dispatch(setLoader(true));
            let body = {
              primaccnum: thisTaxLot[0].properties.PRIMACCNUM,
              searchtype: e.originalEvent.type,
            };
            dispatch(setMapSearch(true));
            dispatch(
              getAddressByLngLat(
                body,
                e.lngLat.lat,
                e.lngLat.lng,
                infoGeocoder,
                homeGeocoder,
                searchBarGeocoder
              )
            );
            setGeoJson(thisTaxLot[0]);
            setCurrentContent("Info");
            fetchPropertyData(
              thisTaxLot[0].properties.PRIMACCNUM,
              map.unproject(e.point)
            );
          }
        }
      );
    });
  }, []);

  useEffect(() => {
    if (lng && lat && isMobile === true) {
      let bufferedPortland = turf.buffer(
        turf.multiPolygon([...CITY_BOUNDARY]),
        20,
        { units: "miles" }
      );
      let isInside = turf.booleanPointInPolygon(
        turf.point([lng, lat]),
        bufferedPortland
      );
      if (isInside) {
        map.flyTo({
          center: [lng, lat],
          zoom: 18,
        });
        const marker = new mapboxgl.Marker({
          color: "#52c1f3",
        })
          .setLngLat([lng, lat])
          .addTo(map);
      }
    }
  }, [lng, lat]);

  useEffect(() => {
    setTimeout(
      function () {
        dispatch(setMapSearch(false));
        if (showMap === true && isMobile === true) {
          const searchBarGeocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: "Search For an address...",
            marker: false,
            countries: "us",
            bbox: BBOX,
            proximity: selectedEnv.REACT_APP_PROXIMITY,
            zoom: 18,
            types: "address",
            filter: (item) => filter(item),
            render: (item) => renderItem(item),
          }).on("result", (e) => {
            dispatch(setLoader(true));
            map.once("idle", () => {
              let thisTaxLot = map.queryRenderedFeatures(
                map.project(e.result.center),
                {
                  layers: selectedEnv.REACT_APP_MB_TILES_LIST.includes(",")
                    ? selectedEnv.REACT_APP_MB_TILES_LIST.split(",")
                    : selectedEnv.REACT_APP_MB_TILES_LIST.split(","),
                }
              );
              if (thisTaxLot && thisTaxLot.length > 0) {
                map.fire("click", {
                  lngLat: e.result.center,
                  point: map.project(e.result.center),
                  originalEvent: { type: "search" },
                });
              } else {
                dispatch(setInfoUpdated(true));
                dispatch(setAlertMessage("No data found!"));
                dispatch(
                  setDataWindow({
                    dataWindow: false,
                    content: {
                      isContact: null,
                      contactInfo: {},
                      choosePlan: {},
                      isPayment: false,
                      isInfo: true,
                    },
                  })
                );
                dispatch(setLoader(false));
              }
            });
          });
          const homeGeocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: "Search For an address...",
            marker: false,
            countries: "us",
            bbox: BBOX,
            proximity: selectedEnv.REACT_APP_PROXIMITY,
            zoom: 18,
            types: "address",
            filter: (item) => filter(item),
            render: (item) => renderItem(item),
          }).on("result", (e) => {
            dispatch(setLoader(true));
            map.once("idle", () => {
              let thisTaxLot = map.queryRenderedFeatures(
                map.project(e.result.center),
                {
                  layers: selectedEnv.REACT_APP_MB_TILES_LIST.includes(",")
                    ? selectedEnv.REACT_APP_MB_TILES_LIST.split(",")
                    : selectedEnv.REACT_APP_MB_TILES_LIST.split(","),
                }
              );
              if (thisTaxLot && thisTaxLot.length > 0) {
                map.fire("click", {
                  lngLat: e.result.center,
                  point: map.project(e.result.center),
                  originalEvent: { type: "search" },
                });
              } else {
                dispatch(setInfoUpdated(true));
                dispatch(setAlertMessage("No data found!"));
                dispatch(
                  setDataWindow({
                    dataWindow: false,
                    content: {
                      isContact: null,
                      contactInfo: {},
                      choosePlan: {},
                      isPayment: false,
                      isInfo: true,
                    },
                  })
                );
                dispatch(setLoader(false));
              }
            });
          });

          const infoGeocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: "Search for an address here...",
            marker: false,
            countries: "us",
            bbox: BBOX,
            proximity: selectedEnv.REACT_APP_PROXIMITY,
            zoom: 18,
            style: { height: "55px" },
            types: "address",
            filter: (item) => filter(item),
            render: (item) => renderItem(item),
          }).on("result", (e) => {
            dispatch(setLoader(true));
            map.once("idle", () => {
              let thisTaxLot = map.queryRenderedFeatures(
                map.project(e.result.center),
                {
                  layers: selectedEnv.REACT_APP_MB_TILES_LIST.includes(",")
                    ? selectedEnv.REACT_APP_MB_TILES_LIST.split(",")
                    : selectedEnv.REACT_APP_MB_TILES_LIST.split(","),
                }
              );
              if (thisTaxLot && thisTaxLot.length > 0) {
                map.fire("click", {
                  lngLat: e.result.center,
                  point: map.project(e.result.center),
                  originalEvent: { type: "search" },
                });
              } else {
                dispatch(setInfoUpdated(true));
                dispatch(setAlertMessage("No data found!"));
                dispatch(
                  setDataWindow({
                    dataWindow: false,
                    content: {
                      isContact: null,
                      contactInfo: {},
                      choosePlan: {},
                      isPayment: false,
                      isInfo: true,
                    },
                  })
                );
                dispatch(setLoader(false));
              }
            });
          });
          setGeoCoder(infoGeocoder);
          let HomeElement = document.getElementById("mapbox-geocoder-home");
          let HomeGeocoderEmpty = false;
          let InfoElement = document.getElementById("mapbox-geocoder-info");
          let InfoGeocoderEmpty = false;
          let SearchElement = document.getElementById(
            "mapbox-geocoder-search-bar"
          );
          let SearchBarGeocoderEmpty = false;

          if (HomeElement !== null)
            HomeGeocoderEmpty =
              document.getElementById("mapbox-geocoder-home").innerHTML === "";
          if (InfoElement !== null)
            InfoGeocoderEmpty =
              document.getElementById("mapbox-geocoder-info").innerHTML === "";
          if (SearchElement !== null)
            SearchBarGeocoderEmpty =
              document.getElementById("mapbox-geocoder-search-bar")
                .innerHTML === "";
          let address = infoDefaultValues?.identifiers
            ? infoDefaultValues.identifiers[0].properties[0]
            : null;
          let searchText = address
            ? Capitalize(address.title) +
              " " +
              Capitalize(address.title_desc) +
              ", " +
              " " +
              address.title_zip_code
            : "";
          if (HomeGeocoderEmpty) {
            document
              .getElementById("mapbox-geocoder-home")
              .appendChild(homeGeocoder.onAdd(map));
            if (searchText) homeGeocoder.setInput(searchText);
            // add event listener for geocoder's input element to trigger the geocoded address list
            homeGeocoder._inputEl.addEventListener("focus", () => {
              if (homeGeocoder._typeahead.data.length) {
                // if there was a list before, just display it, so the user can quickly choose a different result
                homeGeocoder._typeahead.data !== "" &&
                  homeGeocoder._typeahead.update(homeGeocoder._typeahead.data);
              } else {
                // otherwise, get and show results
                homeGeocoder._inputEl.value !== "" &&
                  homeGeocoder._geocode(homeGeocoder._inputEl.value);
              }
            });
          }
          if (InfoGeocoderEmpty) {
            document
              .getElementById("mapbox-geocoder-info")
              .appendChild(infoGeocoder.onAdd(map));
            if (searchText) infoGeocoder.setInput(searchText);
            // add event listener for geocoder's input element to trigger the geocoded address list
            infoGeocoder._inputEl.addEventListener("focus", () => {
              if (infoGeocoder._typeahead.data.length) {
                // if there was a list before, just display it, so the user can quickly choose a different result
                infoGeocoder._typeahead.data !== "" &&
                  infoGeocoder._typeahead.update(infoGeocoder._typeahead.data);
              } else {
                // otherwise, get and show results
                infoGeocoder._inputEl.value !== "" &&
                  infoGeocoder._geocode(infoGeocoder._inputEl.value);
              }
            });
          }
          if (SearchBarGeocoderEmpty) {
            document
              .getElementById("mapbox-geocoder-search-bar")
              .appendChild(searchBarGeocoder.onAdd(map));
            if (searchText) searchBarGeocoder.setInput(searchText);
            // add event listener for geocoder's input element to trigger the geocoded address list
            searchBarGeocoder._inputEl.addEventListener("focus", () => {
              if (searchBarGeocoder._typeahead.data.length) {
                // if there was a list before, just display it, so the user can quickly choose a different result
                searchBarGeocoder._typeahead.data !== "" &&
                  searchBarGeocoder._typeahead.update(
                    searchBarGeocoder._typeahead.data
                  );
              } else {
                // otherwise, get and show results
                searchBarGeocoder._inputEl.value !== "" &&
                  searchBarGeocoder._geocode(searchBarGeocoder._inputEl.value);
              }
            });
          }
        }
      }.bind(this),
      1000
    );
  }, [showMap]);

  useEffect(() => {
    setIsSetFit(true);
    map &&
      map.on("idle", (e) => {
        let temp = map.querySourceFeatures("composite", {
          sourceLayer:
            map.getZoom() > 20
              ? selectedEnv.REACT_APP_MAPBOX_LAYER_NAME
              : selectedEnv.REACT_APP_MAPBOX_LAYER_NAME,
          filter: ["in", "PRIMACCNUM", ...primeAccNums],
        });
        let temp2 = selectedEnv.REACT_APP_SUBREGION_LAYER
          ? map.querySourceFeatures("composite", {
              sourceLayer:
                map.getZoom() > 20
                  ? selectedEnv.REACT_APP_SUBREGION_LAYER
                  : selectedEnv.REACT_APP_SUBREGION_LAYER,
              filter: ["in", "PRIMACCNUM", ...primeAccNums],
            })
          : null;
        // console.log("jack",temp.length,temp2.length,primeAccNums.length)
        if (!map.getSource("filtered-lot")) {
          map.addSource("filtered-lot", {
            type: "geojson",
            data: { type: "FeatureCollection", features: temp },
          });
          map.addLayer({
            id: "filtered-lot",
            source: "filtered-lot",
            type: "line",
            paint: {
              "line-color": "#ff00ff",
              "line-width": 1.62,
            },
          });
        } else {
          if (temp.length) {
            map
              .getSource("filtered-lot")
              .setData({ type: "FeatureCollection", features: temp });
          }
        }
        if (temp.length) {
          map.setLayoutProperty("filtered-lot2", "visibility", "none");
          map.setLayoutProperty("filtered-lot", "visibility", "visible");
          setShowingResults(temp.length);
        }

        // following temp2 will handle the addition of sub-region filter results polygons
        if (temp2) {
          if (!map.getSource("filtered-lot2")) {
            map.addSource("filtered-lot2", {
              type: "geojson",
              data: { type: "FeatureCollection", features: temp2 },
            });
            map.addLayer({
              id: "filtered-lot2",
              source: "filtered-lot2",
              type: "line",
              paint: {
                "line-color": "#ff00ff",
                "line-width": 1.62,
              },
            });
          } else {
            if (temp2.length)
              map
                .getSource("filtered-lot2")
                .setData({ type: "FeatureCollection", features: temp2 });
          }

          if (temp2.length) {
            map.setLayoutProperty("filtered-lot", "visibility", "none");
            map.setLayoutProperty("filtered-lot2", "visibility", "visible");
            setShowingResults(temp2.length);
          }
        }
      });
  }, [primeAccNums]);

  useEffect(() => {
    map &&
      map.once("idle", (e) => {
        let temp = map.querySourceFeatures("composite", {
          sourceLayer:
            map.getZoom() > 20
              ? selectedEnv.REACT_APP_MAPBOX_LAYER_NAME
              : selectedEnv.REACT_APP_MAPBOX_LAYER_NAME,
          filter: ["in", "PRIMACCNUM", ...primeAccNums],
        });

        if (temp.length > 0) {
          let bbox = turf.bbox({
            type: "FeatureCollection",
            features: temp,
          });
          map.fitBounds(bbox, { padding: 20 });
          let temp2 = selectedEnv.REACT_APP_SUBREGION_LAYER
            ? map.querySourceFeatures("composite", {
                sourceLayer:
                  map.getZoom() > 20
                    ? selectedEnv.REACT_APP_SUBREGION_LAYER
                    : selectedEnv.REACT_APP_SUBREGION_LAYER,
                filter: ["in", "PRIMACCNUM", ...primeAccNums],
              })
            : null;
          if (temp2) {
            if (temp2.length > 0) {
              let bbox = turf.bbox({
                type: "FeatureCollection",
                features: temp2,
              });
            }
            if (isFit) {
              setIsSetFit(false);
            }
          }

          map.fitBounds(bbox, { padding: 20 });
          if (isFit) {
            setIsSetFit(false);
          }
        }
      });
  }, [primeAccNums]);
  useEffect(() => {
    if (selectedProperty?.id) {
      setCurrentContent("Info");
      let lng = parseFloat(
        selectedProperty.centre_geom.split(";")[1].split(" ")[0].substr(6)
      );
      let lat = parseFloat(
        selectedProperty.centre_geom.split(";")[1].split(" ")[1].split(")")[0]
      );
      let langlat = {
        lat: lat,
        lng: lng,
      };
      let body = {
        primaccnum: selectedProperty.primaccnum, //same here
        searchtype: "filter",
      };
      dispatch(getAddressByLngLat(body, lat, lng, geoCoder));
      fetchPropertyData(selectedProperty.primaccnum, langlat); //same here
    }
  }, [selectedProperty.id, selectedProperty.primaccnum, setCurrentContent]); //add selectedProperty.primaccnum

  useEffect(() => {
    if (map && taxlotData && taxlotData.taxLots) {
      // If tax lot data changes but isn't selected yet
      if (taxlotData.taxLots.mapboxCoords) {
        map.flyTo({
          center: taxlotData.taxLots.mapboxCoords,
          zoom: 18,
        });
        if (!map.getSource("selected-lot")) {
          map.addSource("selected-lot", {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: JSON.parse(taxlotData.taxLots.geom),
            },
          });
          map.addLayer({
            id: "selected-lot",
            source: "selected-lot",
            type: "line",
            paint: {
              "line-color": "cyan",
              "line-width": 3,
            },
          });
        } else {
          map.getSource("selected-lot").setData({
            type: "Feature",
            geometry: JSON.parse(taxlotData.taxLots.geom),
          });
        }
        map.once("moveend", () => {
          if (!map.getSource("selected-lot")) {
            map.addSource("selected-lot", {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: JSON.parse(taxlotData.taxLots.geom),
              },
            });
            map.addLayer({
              id: "selected-lot",
              source: "selected-lot",
              type: "line",
              paint: {
                "line-color": "cyan",
                "line-width": 3,
              },
            });
          } else {
            map.getSource("selected-lot").setData({
              type: "Feature",
              geometry: JSON.parse(taxlotData.taxLots.geom),
            });
          }
          setGeoJson({ geometry: JSON.parse(taxlotData.taxLots.geom) });
        });
      }
    }
  }, [
    taxlotData,
    isSatelliteView,
    envelope3dStyle,
    isBuildingFootprintsActive,
  ]);

  useEffect(() => {
    if (isBuildingFootprintsActive === false) {
      map && map.setLayoutProperty("bldg-fill", "visibility", "none");
    } else {
      map && map.setLayoutProperty("bldg-fill", "visibility", "visible");
    }
  }, [initialStyle]);

  const buildingFootPrintsSwitcher = (checked) => {
    setIsBuildingFootprintsActive(checked);
    const visibility = map.getLayoutProperty("bldg-fill", "visibility");
    if (visibility === "visible") {
      map.setLayoutProperty("bldg-fill", "visibility", "none");
    } else {
      map.setLayoutProperty("bldg-fill", "visibility", "visible");
    }
  };

  const mapUnderlaysSwitcher = (checked) => {
    setIsSatelliteView(checked);
    setEnvelope3dStyle(false);
    let mapStyle = isSatelliteView
      ? selectedEnv.REACT_APP_MAPBOX_STYLE_URL_BASIC
      : selectedEnv.REACT_APP_MAPBOX_STYLE_URL_SATELLITE;
    //mapbox://styles/qvt282/cksecovrm1uck17mzvfatf489 prod
    //mapbox://styles/qvt282/cl6k904q4000315qmujm278fp dev
    map.setStyle(mapStyle);
    setMapStyleGlobally(mapStyle);
  };

  const setEnvelope3DStyle = (checked) => {
    setEnvelope3dStyle(checked);
    let style = checked
      ? selectedEnv.REACT_APP_MAPBOX_STYLE_URL_MBENVELOPE
      : mapStyleGlobally === null
      ? selectedEnv.REACT_APP_MAPBOX_STYLE_URL_BASIC
      : mapStyleGlobally;
    map.setStyle(style);
  };

  useEffect(() => {
    if (map) {
      var newExpression = ["case", ["all"], 1, 0];

      if (zoneFilterSelectedOptions.length > 0) {
        var zoneMatches = [];
        zoneFilterSelectedOptions.forEach((filter) => {
          zoneMatches.push(filter);
        });

        newExpression[1].push([
          "in",
          ["get", "ZONE"],
          ["literal", zoneMatches],
        ]);
      }

      if (zoneCatFilterSelectedOptions.length > 0) {
        var zoneCatMatches = [];
        zoneCatFilterSelectedOptions.forEach((filter) => {
          zoneCatMatches.push(filter);
        });

        newExpression[1].push([
          "in",
          ["get", "ZONE_CAT"],
          ["literal", zoneCatMatches],
        ]);
      }
      // if (zoneCat2FilterSelectedOptions.length > 0) {
      //   var zoneCat2Matches = [];
      //   zoneCat2FilterSelectedOptions.forEach((filter) => {
      //     zoneCat2Matches.push(filter);
      //   });

      //   newExpression[1].push([
      //     "in",
      //     ["get", "ZONE_CAT2"],
      //     ["literal", zoneCat2Matches],
      //   ]);
      // }

      if (lotTypeFilterSelectedOptions.length > 0) {
        var lotTypeMatches = [];
        lotTypeFilterSelectedOptions.forEach((filter) => {
          lotTypeMatches.push(filter);
        });

        newExpression[1].push([
          "in",
          ["get", "LOT_TYPE"],
          ["literal", lotTypeMatches],
        ]);
      }

      if (htlimLotSelectedOptions) {
        newExpression[1].push(
          [">=", ["get", "HTLIM_ADJX"], htlimLotSelectedOptions[0]],
          ["<=", ["get", "HTLIM_ADJX"], htlimLotSelectedOptions[1]]
        );
      }

      if (htlimLotBonusSelectedOptions) {
        newExpression[1].push(
          [">=", ["get", "HTLIM_BONX"], htlimLotBonusSelectedOptions[0]],
          ["<=", ["get", "HTLIM_BONX"], htlimLotBonusSelectedOptions[1]]
        );
      }

      if (unitLotMinSelectedOptions) {
        newExpression[1].push(
          [">=", ["get", "UNIT_ADJX_VALU"], unitLotMinSelectedOptions[0]],
          ["<=", ["get", "UNIT_ADJX_VALU"], unitLotMinSelectedOptions[1]]
        );
      }

      if (unitLotMaxSelectedOptions) {
        newExpression[1].push(
          [">=", ["get", "UNIT_BONX_VALU"], unitLotMaxSelectedOptions[0]],
          ["<=", ["get", "UNIT_BONX_VALU"], unitLotMaxSelectedOptions[1]]
        );
      }

      if (envpGrndLotAreaSelectedOptions) {
        newExpression[1].push(
          [
            ">=",
            ["get", "ENVP_GRND_LOT_AREA"],
            envpGrndLotAreaSelectedOptions[0],
          ],
          [
            "<=",
            ["get", "ENVP_GRND_LOT_AREA"],
            envpGrndLotAreaSelectedOptions[1],
          ]
        );
      }

      if (envpGrndFtptMaxSelectedOptions) {
        newExpression[1].push(
          [
            ">=",
            ["get", "ENVP_GRND_FTPT_MAX"],
            envpGrndFtptMaxSelectedOptions[0],
          ],
          [
            "<=",
            ["get", "ENVP_GRND_FTPT_MAX"],
            envpGrndFtptMaxSelectedOptions[1],
          ]
        );
      }

      if (envpBldgMaxAreaSelectedOptions) {
        newExpression[1].push(
          [
            ">=",
            ["get", "ENVP_BLDG_MAX_AREA"],
            envpBldgMaxAreaSelectedOptions[0],
          ],
          [
            "<=",
            ["get", "ENVP_BLDG_MAX_AREA"],
            envpBldgMaxAreaSelectedOptions[1],
          ]
        );
      }

      if (envpBldgMaxResSelectedOptions) {
        newExpression[1].push(
          [
            ">=",
            ["get", "ENVP_BLDG_MAX_RES"],
            envpBldgMaxResSelectedOptions[0],
          ],
          ["<=", ["get", "ENVP_BLDG_MAX_RES"], envpBldgMaxResSelectedOptions[1]]
        );
      }

      if (farLotSelectedOptions) {
        newExpression[1].push(
          [">=", ["get", "FAR_LOT"], farLotSelectedOptions[0]],
          ["<=", ["get", "FAR_LOT"], farLotSelectedOptions[1]]
        );
      }

      if (farLotBonusSelectedOptions) {
        newExpression[1].push(
          [">=", ["get", "FAR_BONX"], farLotBonusSelectedOptions[0]],
          ["<=", ["get", "FAR_BONX"], farLotBonusSelectedOptions[1]]
        );
      }

      //   if (envpBldgDeltAreaSelectedOptions) {
      //     newExpression[1].push([">=", ["get", "ENVP_BLDG_DELT_AREA"], envpBldgDeltAreaSelectedOptions[0]], ["<=", ["get", "ENVP_BLDG_DELT_AREA"], envpBldgDeltAreaSelectedOptions[1]]);
      //   }

      const htlimLotTernary =
        htlimLotSelectedOptions[0] > htlimLotDefaultOptions[0] ||
        htlimLotSelectedOptions[1] < htlimLotDefaultOptions[1];
      const htlimLotBonusTernary =
        htlimLotBonusSelectedOptions[0] > htlimLotBonusDefaultOptions[0] ||
        htlimLotBonusSelectedOptions[1] < htlimLotBonusDefaultOptions[1];
      const unitLotMinTernary =
        unitLotMinSelectedOptions[0] > unitLotMinDefaultOptions[0] ||
        unitLotMinSelectedOptions[1] < unitLotMinDefaultOptions[1];
      const unitLotMaxTernary =
        unitLotMaxSelectedOptions[0] > unitLotMaxDefaultOptions[0] ||
        unitLotMaxSelectedOptions[1] < unitLotMaxDefaultOptions[1];
      const envpGrndLotAreaTernary =
        envpGrndLotAreaSelectedOptions[0] > envpGrndLotAreaDefaultOptions[0] ||
        envpGrndLotAreaSelectedOptions[1] < envpGrndLotAreaDefaultOptions[1];
      const envpGrndFtptMaxTernary =
        envpGrndFtptMaxSelectedOptions[0] > envpGrndFtptMaxDefaultOptions[0] ||
        envpGrndFtptMaxSelectedOptions[1] < envpGrndFtptMaxDefaultOptions[1];
      const envpBldgMaxAreaTernary =
        envpBldgMaxAreaSelectedOptions[0] > envpBldgMaxAreaDefaultOptions[0] ||
        envpBldgMaxAreaSelectedOptions[1] < envpBldgMaxAreaDefaultOptions[1];
      const envpBldgMaxResTernary =
        envpBldgMaxResSelectedOptions[0] > envpBldgMaxResDefaultOptions[0] ||
        envpBldgMaxResSelectedOptions[1] < envpBldgMaxResDefaultOptions[1];
      const farLotTernary =
        farLotSelectedOptions[0] > farLotDefaultOptions[0] ||
        farLotSelectedOptions[1] < farLotDefaultOptions[1];
      const farLotBonusTernary =
        farLotBonusSelectedOptions[0] > farLotBonusDefaultOptions[0] ||
        farLotBonusSelectedOptions[1] < farLotBonusDefaultOptions[1];
      //   const envpBldgDeltAreaTernary = envpBldgDeltAreaSelectedOptions[0] > envpBldgDeltAreaDefaultOptions[0] || envpBldgDeltAreaSelectedOptions[1] < envpBldgDeltAreaDefaultOptions[1];

      if (
        zoneFilterSelectedOptions.length > 0 ||
        zoneCatFilterSelectedOptions.length > 0 ||
        lotTypeFilterSelectedOptions.length > 0 ||
        htlimLotTernary ||
        htlimLotBonusTernary ||
        unitLotMinTernary ||
        unitLotMaxTernary ||
        envpGrndLotAreaTernary ||
        envpGrndFtptMaxTernary ||
        envpBldgMaxAreaTernary ||
        envpBldgMaxResTernary ||
        farLotTernary ||
        // envpBldgDeltAreaTernary ||
        farLotBonusTernary
      ) {
        if (map.getLayer("taxlotsfilterselectonly-line")) {
          map.setPaintProperty(
            "taxlotsfilterselectonly-line",
            "line-opacity",
            newExpression
          );
        }
      } else {
        if (map.getLayer("taxlotsfilterselectonly-line")) {
          map.setPaintProperty(
            "taxlotsfilterselectonly-line",
            "line-opacity",
            0
          );
        }
      }
    }
  }, [
    zoneFilterSelectedOptions,
    zoneCatFilterSelectedOptions,
    lotTypeFilterSelectedOptions,
    htlimLotSelectedOptions,
    htlimLotBonusSelectedOptions,
    unitLotMinSelectedOptions,
    unitLotMaxSelectedOptions,
    envpGrndLotAreaSelectedOptions,
    envpGrndFtptMaxSelectedOptions,
    envpBldgMaxAreaSelectedOptions,
    envpBldgMaxResSelectedOptions,
    // envpBldgDeltAreaSelectedOptions,
    farLotSelectedOptions,
    farLotBonusSelectedOptions,
  ]);

  // Resize map if sidebar has changed
  useEffect(() => {
    if (map)
      setTimeout(() => {
        map.resize();
      }, 500);
  }, [sidebarVisible, dataWindow]);

  const handleGoToCity = (site) => {
    const token = localStorage.getItem("_uft");
    if (window.location.href === site) {
      map.flyTo({
        center: MAP_CENTER,
        zoom: 12.8,
      });
    } else {
      const updatedSiteAddress = token
        ? site + "?t=" + token
        : site + "?s=expired";
      window.open(updatedSiteAddress, "_self");
    }
  };

  return (
    <>
      <div id="mapbox-map" className="mbl-mab-box">
        <div
          className={[cls]}
          style={{
            background: isMobile
              ? "rgba(255, 255, 255, .25)"
              : "rgba(255, 255, 255, .85)",
          }}
        >
          <div className="underlays-switcher">
            <div className="switch-container">
              <Switch
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                offHandleColor="#000"
                offColor="#fff"
                onHandleColor="#fff"
                onColor="#43c1f3"
                onChange={mapUnderlaysSwitcher}
                checked={isSatelliteView}
              />
              <span className="bld-status value-03">
                {isSatelliteView === true ? "Satellite View" : "Streets"}
              </span>
            </div>
          </div>
          <div className="toggle-switcher">
            <div className="switch-container">
              <Switch
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                offHandleColor="#000"
                offColor="#fff"
                onHandleColor="#fff"
                onColor="#43c1f3"
                onChange={buildingFootPrintsSwitcher}
                checked={isBuildingFootprintsActive}
              />
              <span className="bld-status value-03">Building Footprints</span>
            </div>
          </div>
          <div className="envelope-3d-switcher">
            <div className="switch-container">
              <Switch
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                offHandleColor="#000"
                offColor="#fff"
                onHandleColor="#fff"
                onColor="#43c1f3"
                onChange={setEnvelope3DStyle}
                checked={envelope3dStyle}
              />
              <span className="envelope-3d-status value-03">
                {/* {envelope3dStyle === true ? "ON" : "OFF"} */}
                MaxBuild 3d
              </span>
            </div>
          </div>
          {dynoButtonsResponse && (
            <div className="city-switcher">
              {dynoButtonsResponse.map((but) => (
                <div>
                  <Button
                    fluid
                    className="value-03"
                    basic
                    onClick={() => handleGoToCity(but.url)}
                  >
                    <Image src={fly} />
                    <span>Go to {but.name}</span>
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
        {totalResults && (
          <div className="feature-count-wrapper value-03">
            <span>
              Showing {showingResults} results out of {totalResults} total
            </span>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  zoneFilterSelectedOptions: state.filters.filterSelectedValues.zone,
  zoneCatFilterSelectedOptions: state.filters.filterSelectedValues.zone_cat,
  lotTypeFilterSelectedOptions: state.filters.filterSelectedValues.lot_type,
  htlimLotDefaultOptions: state.filters.filterDefaultValues.htlim_adjx,
  htlimLotSelectedOptions: state.filters.filterSelectedValues.htlim_adjx,
  htlimLotBonusDefaultOptions: state.filters.filterDefaultValues.htlim_bonx,
  htlimLotBonusSelectedOptions: state.filters.filterSelectedValues.htlim_bonx,
  unitLotMinDefaultOptions: state.filters.filterDefaultValues.umax_adjx_valu,
  unitLotMinSelectedOptions: state.filters.filterSelectedValues.umax_adjx_valu,
  unitLotMaxDefaultOptions: state.filters.filterDefaultValues.umax_bonx_valu,
  unitLotMaxSelectedOptions: state.filters.filterSelectedValues.umax_bonx_valu,
  envpGrndLotAreaDefaultOptions:
    state.filters.filterDefaultValues.envp_delt_area,
  envpGrndLotAreaSelectedOptions:
    state.filters.filterSelectedValues.envp_delt_area,
  envpGrndFtptMaxDefaultOptions:
    state.filters.filterDefaultValues.envp_delt_bonus,
  envpGrndFtptMaxSelectedOptions:
    state.filters.filterSelectedValues.envp_delt_bonus,
  envpBldgMaxAreaDefaultOptions:
    state.filters.filterDefaultValues.envp_bldg_max_area,
  envpBldgMaxAreaSelectedOptions:
    state.filters.filterSelectedValues.envp_bldg_max_area,
  envpBldgMaxResDefaultOptions:
    state.filters.filterDefaultValues.envp_bldg_max_res,
  envpBldgMaxResSelectedOptions:
    state.filters.filterSelectedValues.envp_bldg_max_res,

  //   envpBldgDeltAreaDefaultOptions: state.filters.filterDefaultValues.envp_bldg_delt_area,
  //   envpBldgDeltAreaSelectedOptions: state.filters.filterSelectedValues.envp_bldg_delt_area,

  farLotDefaultOptions: state.filters.filterDefaultValues.far_lot,
  farLotSelectedOptions: state.filters.filterSelectedValues.far_lot,
  farLotBonusDefaultOptions: state.filters.filterDefaultValues.far_bonx,
  farLotBonusSelectedOptions: state.filters.filterSelectedValues.far_bonx,
  taxlotData: state.taxlot.currentTaxlot,
});

export default connect(mapStateToProps)(Map);
