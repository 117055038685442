export const la_env = {
    REACT_APP_API_URL: "https://sea-dev.urbanform.us/api",
    REACT_APP_MAPBOX_ACCESS_TOKEN:
      "pk.eyJ1IjoicXZ0MjgyIiwiYSI6ImNrZmtxOWdveDB2ZXkyeG56NzR0aW9jbXUifQ.Plxub9XCzhhAbZ3MJbAXMw",
    REACT_APP_MAPBOX_STYLE_URL_BASIC:
      "mapbox://styles/qvt282/clx3od61i01fh01rbdkh993w5",//multi-region-style
    REACT_APP_MAPBOX_STYLE_URL_SATELLITE:
      "mapbox://styles/qvt282/clx3oda0p01r901po1xoyg2gs",
    REACT_APP_MAPBOX_STYLE_URL_MBENVELOPE:
      "mapbox://styles/qvt282/clx3ocmxv01r801podj8b7ugf",
    REACT_APP_MAPBOX_LAYER_NAME: "la_taxlots",
    REACT_APP_STRIPE_KEY:
      "pk_test_51J3keRKwuRPRmetLYA1Zgpg1T50OlJj7L5U7p60MB7hvR8VaaXhoDk0llLi95tOaWUU77c4rNEx7fa22nyEStrxD00X4iN7RXg",
    REACT_APP_REGION: "los_angeles",
    REACT_APP_BBOX: "-118.589172,33.694638,-117.976685,34.198173",
    REACT_APP_MAP_CENTER: "-118.274503022256, 34.053989378733",
      REACT_APP_SUBREGION_LAYER:"",
    REACT_APP_SUB_REGION: null,
    REACT_APP_SUB_CITY_NAME: null,
    REACT_APP_CITY_NAME: "Los Angeles, California",
    REACT_APP_CITIES_LIST:
      '{ "portland": [], "seattle": [], "san_diego": [], "austin": [],"los_angeles": [] }',
    REACT_APP_PROXIMITY: "-118.274503022256, 34.053989378733", 
    REACT_APP_MB_TILES_LIST:"taxlots-fill",
    REACT_APP_ZOOM_LEVEL:10    
  };
  